<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>C端数据</a-breadcrumb-item>
        <a-breadcrumb-item>租赁订单</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <common-search
        :fields="fields"
        @search="onSearch"
        @reset="onReset"
        ref="searchForm"
      >
        <template>
          <a-form-item label="订单类型" class="common-search_form-item">
            <a-select v-model="orderType" @change="orderTypeChange">
              <a-select-option
                :value="item.value"
                v-for="item in orderTypeOptions"
                :key="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </template>
        <template>
          <a-form-item label="订单状态" class="common-search_form-item">
            <a-select v-model="orderStatus">
              <a-select-option
                :value="item.value"
                v-for="item in orderStatusOptions"
                :key="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </template>
      </common-search>
      <div class="main-content">
        <div class="export">
          <a-button
            type="primary"
            @click="exportExcel()"
            :loading="exportLoading"
          >
            导出
          </a-button>
          <span
            style="margin-left: 12px;color: #ff5757"
            v-show="exportTime > 10"
          >
            订单数据过大，请耐心等待导出…
          </span>
        </div>
        <a-table
          :data-source="tblData"
          :columns="columns"
          :pagination="pagination"
          @change="onPage"
          :scroll="{ x: 1300 }"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="createTime" slot-scope="text, record">
            <div>{{ record.createTime || "--" }}</div>
          </template>
          <template slot="goodsSn" slot-scope="text, record">
            <div>{{ record.goodsSn || "--" }}</div>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record.orderSn)">
              查看
            </a>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { dtFormat, tsFormat } from "@/components/DateUtils";
import { loadArea } from "@/services/DealerService";
import { baseURL, imageBaseUrl } from "@/services/HttpService";
import { buildHeaders } from "@/components/utils";
import { fetchCorporationList } from "@/services/ActivityService";
import { operationHeadquartersSiteInfo } from "@/services/ValueBag";
import {
  exportOrdersExcel,
  getDXSJLeaseOrderList
} from "@/services/LeaseManage";

const columns = [
  {
    title: "订单号",
    dataIndex: "orderSn",
    width: 300,
    fixed: "left"
  },
  {
    title: "下单时间",
    key: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: 200
  },
  {
    title: "用户姓名",
    dataIndex: "customerName",
    width: 200
  },
  {
    title: "资产序列号",
    dataIndex: "goodsSn",
    scopedSlots: { customRender: "goodsSn" },
    width: 200
  },
  {
    title: "运营商",
    dataIndex: "corporationName",
    width: 200
  },
  {
    title: "经营地区",
    dataIndex: "registeredAreaName",
    width: 200
  },
  {
    title: "投放站点",
    dataIndex: "siteName",
    width: 200
  },
  {
    title: "订单状态",
    dataIndex: "orderStatusValue",
    width: 200
  },
  {
    title: "订单类型",
    dataIndex: "schemeModeValue",
    width: 200
  },
  {
    title: "账单状态",
    dataIndex: "billStatusValue",
    width: 200
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    width: 200
  }
];
export default {
  data() {
    return {
      fields: [
        {
          label: "订单号",
          key: "orderSn",
          component: "j-input"
        },
        {
          label: "用户名称",
          key: "userName",
          component: "j-input"
        },
        {
          label: "资产序列号",
          key: "goodsSn",
          component: "j-input"
        },
        {
          label: "运营商",
          key: "distributorId",
          component: "j-select-search",
          options: [],
          getOptions: () => {
            return this.onLoadCorporation();
          }
        },
        {
          label: "投放站点",
          key: "siteId",
          loadFirst: true,
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.getSiteOptions(value);
          }
        },
        {
          label: "经营地区",
          key: "provinceCode,cityCode,countyCode",
          component: "j-cascader",
          fieldNames: {
            label: "areaName",
            value: "areaCode",
            children: "subAreas"
          },
          areaData: [],
          getAreaData: () => {
            return this.onLoadArea();
          }
        },
        {
          label: "账单状态",
          key: "billStatus",
          component: "j-select-search",
          options: [
            { label: "全部", value: "" },
            { label: "已支付", value: "HAVE_PAID" },
            { label: "待支付", value: "TO_PAID" },
            { label: "欠费", value: "arrears" },
            { label: "结清", value: "settled" },
            { label: "取消", value: "canceled" }
          ]
        },
        {
          label: "下单时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        }
      ],
      orderType: "", // 订单类型
      orderStatus: "", // 订单状态
      tsFormat,
      dtFormat,
      form: {},
      tblData: [],
      lessGoodsVoList: [],
      imageBaseUrl,
      columns,
      visible: false,
      buildHeaders,
      baseURL,
      headers: buildHeaders(),
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      loading: false,
      // 订单类型
      orderTypeOptions: [
        { name: "全部", value: "" },
        { name: "租赁", value: "rent" },
        { name: "分期购车", value: "lease_purchase" }
      ],
      // 订单状态(当订单类型为全部时)
      orderStatusOptions: [
        { name: "待支付", value: "TO_PAID" },
        { name: "待取车", value: "to_collected" },
        { name: "租用中", value: "leasing" },
        { name: "分期购买中", value: "purchasing" },
        { name: "租用结束", value: "complete" },
        { name: "购车完成", value: "purchase_complete" },
        { name: "已取消", value: "canceled" },
        { name: "已退车", value: "returned" },
        { name: "续租中", value: "renew_lease" }
      ],
      exportLoading: false,
      exportTime: 0
    };
  },
  async mounted() {
    await this.getSiteOptions("");
    if (this.$route.query.siteId) {
      this.$refs.searchForm.form.siteId = this.$route.query.siteId;
      this.onSearch(this.$refs.searchForm.form);
      this.$router.replace({ name: "TrialLeaseOrderDxsj" });
    } else {
      this.onSearch({});
    }
  },
  methods: {
    //加载机构信息
    async onLoadCorporation() {
      let options = [];
      const resp = await fetchCorporationList("endConsumerMarket");
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          options.push({
            value: item.distributorId,
            label: `${item.corporationName}(${item.distributorTypeValue})`
          });
        });
      }
      return options;
    },
    // 加载行政区数据
    async onLoadArea() {
      const resp = await loadArea();
      let areaData = [];
      if (resp.data.code === "SUCCESS") {
        areaData = resp.data.data;
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return areaData;
    },
    // 加载投放站点数据
    async getSiteOptions(value = "") {
      let params = {
        siteName: value,
        pageNum: 1,
        pageSize: 100,
        startTime: "",
        endTime: "",
        corporationId: "",
        siteAdminName: "",
        status: "",
        phone: ""
      };
      const { data } = await operationHeadquartersSiteInfo(params);
      return data.data.records.map(item => {
        return {
          value: item.siteId,
          label: item.siteName
        };
      });
    },
    // 获取表格数据
    async fetchList(form) {
      const params = {
        orderSn: form.orderSn || "",
        provinceCode: form.provinceCode || "",
        cityCode: form.cityCode || "",
        countyCode: form.countyCode || "",
        userName: form.userName || "",
        distributorId: form.distributorId || "",
        siteId: form.siteId || "",
        goodsSn: form.goodsSn || "",
        billStatus: form.billStatus || "",
        startTime: form.startTime ? form.startTime + " 00:00:00" : "",
        endTime: form.endTime ? form.endTime + " 23:59:59" : "",
        orderType: this.orderType,
        orderStatus: this.orderStatus,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      const { data } = await getDXSJLeaseOrderList(params);
      if (data.code === "SUCCESS") {
        this.tblData = data.data.records;
        this.pagination.total = data.data.total;
        this.pagination.showTotal = function(total, range) {
          return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
        };
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.fetchList(form);
    },
    //重置
    onReset() {
      this.orderType = "";
      this.orderStatus = "";
      this.orderStatusOptions = [
        { name: "待支付", value: "TO_PAID" },
        { name: "待取车", value: "to_collected" },
        { name: "租用中", value: "leasing" },
        { name: "分期购买中", value: "purchasing" },
        { name: "租用结束", value: "complete" },
        { name: "购车完成", value: "purchase_complete" },
        { name: "已取消", value: "canceled" },
        { name: "已退车", value: "returned" },
        { name: "续租中", value: "renew_lease" }
      ];
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList(this.form);
    },
    // 查看详情
    onDetail(id) {
      this.$router.push({
        name: "TrialLeaseDetailDxsj",
        params: {
          id: id
        }
      });
    },
    // 订单类型值改动时触发
    orderTypeChange(value) {
      if (!value) {
        // 全部
        this.orderStatusOptions = [
          { name: "待支付", value: "TO_PAID" },
          { name: "待取车", value: "to_collected" },
          { name: "租用中", value: "leasing" },
          { name: "分期购买中", value: "purchasing" },
          { name: "租用结束", value: "complete" },
          { name: "购车完成", value: "purchase_complete" },
          { name: "已取消", value: "canceled" },
          { name: "已退车", value: "returned" },
          { name: "续租中", value: "renew_lease" }
        ];
      } else if (value === "rent") {
        // 租赁
        this.orderStatusOptions = [
          { name: "待支付", value: "TO_PAID" },
          { name: "待取车", value: "to_collected" },
          { name: "租用中", value: "leasing" },
          { name: "租用结束", value: "complete" },
          { name: "已取消", value: "canceled" },
          { name: "已退车", value: "returned" },
          { name: "续租中", value: "renew_lease" }
        ];
      } else if (value === "lease_purchase") {
        // 分期购车
        this.orderStatusOptions = [
          { name: "待支付", value: "TO_PAID" },
          { name: "待取车", value: "to_collected" },
          { name: "分期购买中", value: "purchasing" },
          { name: "购车完成", value: "purchase_complete" },
          { name: "已取消", value: "canceled" },
          { name: "已退车", value: "returned" }
        ];
      }
    },
    exportExcel() {
      const form = this.form || {};
      console.log("导出");
      const params = {
        orderSn: form.orderSn || "",
        provinceCode: form.provinceCode || "",
        cityCode: form.cityCode || "",
        countyCode: form.countyCode || "",
        userName: form.userName || "",
        distributorId: form.distributorId || "",
        siteId: form.siteId || "",
        goodsSn: form.goodsSn || "",
        billStatus: form.billStatus || "",
        startTime: form.startTime ? form.startTime + " 00:00:00" : "",
        endTime: form.endTime ? form.endTime + " 23:59:59" : "",
        orderType: this.orderType,
        orderStatus: this.orderStatus
      };
      this.exportLoading = true;
      const setTime = setInterval(() => {
        this.exportTime++;
      }, 1000);
      exportOrdersExcel(params).then(response => {
        clearInterval(setTime);
        this.exportTime = 0;
        this.exportLoading = false;
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fURL = document.createElement("a");
        fURL.href = fileURL;
        fURL.setAttribute("download", "租赁订单.xlsx");
        document.body.appendChild(fURL);
        fURL.click();
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
  margin-right: 20px;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
  margin-right: 20px;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: left;
  min-width: 100px;
}

.row {
  display: flex;
}

.btn-reset {
  margin-bottom: 8px;
  margin-left: 10px;
}

.search__btn button {
  margin-right: 8px;
}

.action button {
  margin-right: 8px;
}

.product-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/deep/ .ant-space-item {
  white-space: nowrap;
}

/deep/ .ant-table-thead > tr > th {
  white-space: nowrap;
}

/deep/ .ant-modal {
  width: 720px !important;
}

.advanced-search-forms /deep/ .ant-row {
  display: flex;
  justify-content: space-between;
}

.advanced-search-forms /deep/ .ant-row .ant-col-10 {
  flex: 1;
}

.advanced-search-forms /deep/ .ant-row .ant-col-10:first-child {
  margin-right: 30px;
}

.advanced-search-forms
  /deep/
  .ant-row
  .ant-col
  .ant-form-item
  .ant-form-item-label {
  min-width: 81px;
}

/deep/ .ant-modal {
  width: 81% !important;
}

/deep/ .ant-modal-body {
  overflow-y: auto;
  max-height: 61vh;
}

.goods {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.car__detail {
  width: 50%;
  display: flex;
  align-items: center;
  height: 90px;
  margin-bottom: 8px;
}

.car__img {
  width: 30%;
  height: 90px;
  overflow: hidden;
}

.car__img img {
  width: 100%;
  object-fit: cover;
}

.car__info {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}

.info__word {
  color: rgb(113, 113, 113);
}

.info__title {
  font-size: 16px;
  color: rgb(16, 16, 16);
}

.card-item-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.label {
  color: rgba(0, 0, 0, 0.85);
}

.label__content {
  margin: 24px 0;
}

.openPhoto {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(58, 110, 248);
  cursor: pointer;
}

.openPhoto span {
  margin: 0 4px 20px;
}

.card__title {
  color: rgba(0, 0, 0, 0.85);
  margin: 4px 0;
  font-size: 16px;
}

.common-search_form-item {
  width: 25%;
  display: inline-flex;
  margin-bottom: 24px;
}
.export {
  margin-bottom: 12px;
}
</style>
